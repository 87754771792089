












































































































































































































import Vue from "vue";
import { IInvitation } from "@cyber-range/cyber-range-api-invitation-client";
import { UserRole } from "@cyber-range/cyber-range-api-user-client";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import StoreApiGetter from "@interfaces/storeGetter";
import StoreGetter from "@interfaces/storeGetter";
import VueRecaptcha from "vue-recaptcha";
import Config from '@/config';
import ErrorDialog from '@/components/utils/ErrorDialog.vue'
import { BusinessUnitCategory, IBusinessUnit, IBusinessUnitApiClient } from "@cyber-range/cyber-range-api-business-unit-client";
import { IOrganizationApiClient, IOrganizationCode } from "@cyber-range/cyber-range-api-organization-client";
import { Subscriber, EmailFormat, Topic, NewsletterApiClient } from "@cyber-range/cyber-range-api-newsletter-client";
import Domain from '@interfaces/domain';
import { IApiPageResponse } from "@cyber-range/cyber-range-api-client";
import { validateAll } from 'utils/validateAll';
import AccessibleCombobox from '@components/utils/AccessibleCombobox.vue';
import AccessibleForm from '@components/utils/AccessibleForm.vue'

@Component({
    components: { VueRecaptcha,ErrorDialog, AccessibleCombobox, AccessibleForm }
})
export default class UscrInstructorSignUp extends Vue {
    @Getter(StoreApiGetter.InvitationApiClient) invitationApiClient;
    @Getter(StoreApiGetter.BusinessUnitApiClient) businessUnitApiClient: IBusinessUnitApiClient;
    @Getter(StoreApiGetter.BackgroundOrganizationApiClient) organizaztionApiClient: IOrganizationApiClient;
    @Getter(StoreApiGetter.NewsletterApiClient) newsletterApiClient: NewsletterApiClient;
    @Getter(StoreGetter.BusinessUnitDisplayName) businessUnitDisplayName: (organization: object) => string;
    @Getter(StoreGetter.BusinessUnitDisplayRegion) businessUnitDisplayRegion: (organization: object) => string;
    @Getter(StoreGetter.BusinessUnitDisplayCategory) businessUnitDisplayCategory: (organization: object) => string;
    @Getter(StoreGetter.IsLoading) loading: boolean;
    //state management
    mailChimpError:boolean = false;
    firstName:string = '';
    lastName:string = '';
    finished = false;
    validated = false;
    attemptedValidation = false; 
    validatingCode = false;
    verified: boolean = false;
    submissionAttempted: boolean = false;
    maxFieldLength = 255;
    vueRecaptchaSitekey = Config.VUE_RECAPTCHA_SITEKEY;
    //selection population
    selectedOrganization: IOrganizationCode = <IOrganizationCode>{
        organizationId: "",
        organizationName: "",
        value: "",
        customAttributes: []
    };

    onCaptchaVerified(): void {
        this.verified = true;
    }

    onCaptchaExpired(): void {
        this.verified = false;
    }

    get showCaptchaErrorMessage(): boolean {
        return !this.verified && this.submissionAttempted;
    }

    cancel(){
        this.code = '';
        this.validated = false;
        this.attemptedValidation = false;
        this.$emit('cancel');
    }

    businessUnitSelectorErrorMessage(field: string): string | string[] {
        return this.$validator.errors.first(field) && (!this.creatingBusinessUnit || !this.creatingBusinessUnitRegion)
            ? <string>this.$root.$t('BUSINESS_UNIT_FIELD_REQUIRED_ERROR_TEXT', {field: this.businessUnitFieldName(field)})
            : [];
    }

    businessUnitErrorMessage(field: string): string | string[] {
        return this.$validator.errors.first(field) && this.creatingBusinessUnit
            ? <string>this.$root.$t('BUSINESS_UNIT_FIELD_REQUIRED_ERROR_TEXT', {field: this.businessUnitFieldName(field)})
            : [];
    }

    businessUnitFieldName(field: string): string {
        if (field === "businessUnitDisplaySelector") {
            field = "businessUnitDisplayName";
        }
        if (field === "businessUnitRegionSelector") {
            field = "businessUnitDisplayRegion";
        }
        return this[field](this.selectedOrganization).toLowerCase();
    }

    updateCodeValidation(): void {
        this.attemptedValidation = false;
    }

    selectedSchool: string = "";
    selectedBusinessRegion: string = "";
    businessUnits = [];
    newsLetterChecked: boolean = true;

    //When Creating a Businuess Unit
    readonly otherSchoolStub = {
        name: "Other",
        region: "Other",
        id: "otherSchoolId",
        displayText: "Other"
    };

    code = "";
    invitation: IInvitation = <any>{
        name: "",
        email: "",
        businessUnit: "",
        businessRegion: "",
        businessCategory: "",
        title: "",
        businessUnitId: "",
        organizationId: ""
    };

    get creatingBusinessUnit(): boolean {
        return this.selectedSchool === this.otherSchoolStub.id;
    }

    get creatingBusinessUnitRegion(): boolean {
        return this.selectedBusinessRegion === this.otherSchoolStub.region;
    }

    async validateOrganizationCode(attemptedCode?: string): Promise<void> {
        this.validatingCode = this.code ? true : false;
        attemptedCode = attemptedCode || this.code;

        if(attemptedCode.length === 0){
            this.attemptedValidation = true;
            this.validatingCode = false;
            return;
        }

        try {
            const code = await this.organizaztionApiClient.getOrganizationCode(attemptedCode);
            this.selectedOrganization = code;
            this.invitation.organizationId = code.organizationId;
            this.validated = true;
            this.$emit('submitting', true);
        } catch (e) {
            this.validated = false;
        }
        if (this.code !== "") {
            this.attemptedValidation = true;
        }
        this.validatingCode = false;
    }

    get businessUnitCategories(): {key: string, value: string}[] {
        return Object.keys(BusinessUnitCategory).map(key => ({key: key, value: BusinessUnitCategory[key]}));
    }

    get pageDomain(): string {
        return window.location.hostname.split(".")[0];
    }

    get queryCode(): string {
        return Array.isArray(this.$route.query.code) ? this.$route.query.code[0] : this.$route.query.code;
    }

    async created() {
        let request: IApiPageResponse<IBusinessUnit>;
        do
        {
            request = await this.businessUnitApiClient.get({
                token: request?.nextPageToken
            });
            this.businessUnits.push(...request.items);
        }
        while ( request?.nextPageToken )

        for (let bu of this.businessUnits) {
            bu["displayText"] = `${bu.name} : ${bu.region}`;
        }

        this.businessUnits = this.businessUnits.sort((a, b) => {
            return a.displayText < b.displayText ? -1 : 1;
        });

        let code = this.queryCode || this.pageDomain;

        if (code) {
            await this.validateOrganizationCode(code);
        }
    }

    get organizationBusinessUnits(): IBusinessUnit[] {
        let businessUnits = this.businessUnits.filter(
            bu => bu.organizationId === this.selectedOrganization.organizationId
        ).map(bu=>Object.assign({displayText:`${bu.name} : ${bu.region}`},bu ));
        businessUnits.push(this.otherSchoolStub);

        return businessUnits;
    }

    get organizationRegions(): string[] {
        return this.organizationBusinessUnits.map(bu => bu.region);
    }

    async confirm() {
        this.invitation.name = (`${this.firstName} ${this.lastName}`).trim();;
        if (await validateAll(this) && this.verified) {
            if (this.invitation.businessUnit === "") {
                this.invitation.businessUnit = this.selectedSchool;
            }
            if (this.invitation.businessRegion === "") {
                this.invitation.businessRegion = this.selectedBusinessRegion;
            }
            let chosenSchool: IBusinessUnit = this.businessUnits.find(bu => bu.id === this.selectedSchool);

            if (chosenSchool !== undefined) {
                this.invitation.businessRegion = chosenSchool.region;
                this.invitation.businessCategory = <any>chosenSchool.category;
                this.invitation.businessUnit = chosenSchool.name;
                this.invitation.businessUnitId = chosenSchool.id;
            }

            if (this.creatingBusinessUnit) {
                delete this.invitation.businessUnitId;
            }
            //This is here because if the length is 1, that means other is the only option,
            //So we can't create an invitation with a business unit reference.
            if (this.organizationBusinessUnits.length === 1) {
                delete this.invitation.businessUnitId;
                delete this.invitation.businessUnit;
                delete this.invitation.businessCategory;
                delete this.invitation.businessRegion;
            }
            this.invitation.roles = [
                    UserRole.OrganizationInstructor
            ]
            if(this.newsLetterChecked)
            {
                try{
                    await this.newsletterApiClient.create(
                        new Subscriber(
                            {
                                name: this.invitation.name,
                                emailAddress: this.invitation.email,
                                businessUnit: this.invitation.businessUnit,
                                emailFormat: EmailFormat.HTML,
                                jobTitle: this.invitation.title,
                                topics: [(Config.CYBER_RANGE_UI_BASE_URL.includes(Domain.USCR)?Topic.UscrGeneral:Topic.VacrGeneral)]
                            }
                        )
                    )
                }
                catch(e)
                {
                    if(e.message.includes("looks fake or invalid"))
                    {
                        this.mailChimpError = true;
                    }
                    else
                    {
                        throw e
                    }
                }
                
              
            }
            if(!this.mailChimpError)
            {
                await this.invitationApiClient.add([this.invitation]);
                this.finished = true;
            }
           
        }else{
            this.submissionAttempted = true;
        }
    }
}
